import React, { useEffect, useState } from 'react';
import { useParams,useNavigate } from 'react-router-dom';
import {
  Box,
  Grid,
  Typography,
  Card,
  Chip,
  CardContent,
  Avatar,
  Slider,
  IconButton,
  Menu,
  MenuItem
} from '@mui/material';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import CandleChart from './CandleChart';
import CoinNav from './CoinNav';
import NightlightIcon from '@mui/icons-material/Nightlight';
import BoltIcon from '@mui/icons-material/Bolt';
import PowerIcon from '@mui/icons-material/Power';
import WhatshotIcon from '@mui/icons-material/Whatshot';
const apiUrl = process.env.REACT_APP_API_URL;
const IOSSlider = styled(Slider)(({ theme }) => ({
    color: theme.palette.mode === 'dark' ? '#0a84ff' : '#e0e0e0',
    height: 5,
    padding: '15px 0',
    '& .MuiSlider-thumb': {
      height: 12,
      width: 12,
      backgroundColor: '#dedede',
      boxShadow: '0 0 2px 0px rgba(0, 0, 0, 0.1)',
      '&:focus, &:hover, &.Mui-active': {
        boxShadow: '0px 0px 3px 1px rgba(0, 0, 0, 0.1)',
        '@media (hover: none)': {
          boxShadow: '0 0 1px 1px rgba(0, 0, 0, 0.1)',
        },
      },
      '&:before': {
        boxShadow:
          '0px 0px 1px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 1px 0px rgba(0,0,0,0.12)',
      },
    },
    '& .MuiSlider-valueLabel': {
      fontSize: 14,
      fontWeight: 'regular',
      top: 20,
      left: 10,
      backgroundColor: 'unset',
      color: theme.palette.text.primary,
      '&::before': {
        display: 'none',
      },
      '& *': {
        background: 'transparent',
        color: theme.palette.mode === 'dark' ? '#fff' : '#000',
      },
    },
    '& .MuiSlider-track': {
      border: 'none',
      height: 5,
      color: '#bfbebd',
    },
    '& .MuiSlider-rail': {
      opacity: 0.5,
      backgroundColor: '#d0d0d0',
    },
  }));
const formatPercentage = (value) => value !== undefined ? value.toFixed(1) : 'N/A';
const CoinDetails = () => {
  const { coinId } = useParams();
  const [coinData, setCoinData] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true; // Add this line
    // Replace this with your actual API call
    fetch(`${apiUrl}/api/getCoinData?coin=${coinId}`)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.length > 0) {
          setCoinData(data[0]); // Assuming the API returns an array and we're interested in the first item
        }
      });
      return () => {
        isMounted = false; // Add this line
      };
  }, [coinId]);

  if (!coinData) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  const handleTradeClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const exchanges = [
    { name: 'Binance', icon: 'binance.png', link: (coinname) => `https://www.binance.com/en/trade/${coinname}_USDT?type=spot` },
    { name: 'Gate.io', icon: 'gateio.png', link: (coinname) => `https://www.gate.io/trade/${coinname}_USDT` },
  ];
  

  return (
    <Box sx={{ p: 2, display: 'flex', justifyContent: 'center', minHeight: '100vh'}}>
    <Helmet>
      <title>{coinData ? `${coinData.coinname} price today, ${coinData.coinname} crpyto to USDT price chart | Stratalla` : 'Loading...'}</title>
      <meta name="description" content={`Latest ${coinData?.coinname} price is $${coinData["Close price"]} USDT. Use indicators: crypto price swings, volume categories on Statalla.`} />
      <link rel="canonical" href={`${apiUrl}/cryptocurrency/${coinData.coinname}`} />
      
      {/* Open Graph / Facebook */}
      <meta property="og:title" content={`${coinData.coinname} price today, ${coinData.coinname} crpyto to USDT price chart | Stratalla`} />
      <meta property="og:description" content={`Latest ${coinData?.coinname} price is $${coinData["Close price"]} USDT. Use indicators: crypto price swings, volume categories on Statalla.`} />
      <meta property="og:image" content={`${process.env.PUBLIC_URL}/PNG/${coinData?.coinname}.png`} />
      <meta property="og:url" content={`${apiUrl}/cryptocurrency/${coinData?.coinname}`} />
      <meta property="og:type" content="website" />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={`${coinData.coinname} price today, ${coinData.coinname} crpyto to USDT price chart | Stratalla`} />
      <meta name="twitter:description" content={`Latest ${coinData?.coinname} price is $${coinData["Close price"]} USDT. Use indicators: crypto price swings, volume categories on Statalla.`} />
      <meta name="twitter:image" content={`${process.env.PUBLIC_URL}/PNG/${coinData?.coinname}.png`} />
      <meta name="twitter:url" content={`${apiUrl}/cryptocurrency/${coinData?.coinname}`} />
    </Helmet>
      <Card sx={{
          width: '100%',
          maxWidth: { xs: '100%', md: '100vh' }, // Adjust card width based on screen size
          minWidth: { xs: '100%', md: '30vh' }, 
        }}>

        <CardContent>
          
          <Grid container spacing={2}>
            <Grid item xs={12}>

            <CoinNav />             
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginBottom:'10px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box
                    component="img"
                    src={`${process.env.PUBLIC_URL}/PNG/${coinData.coinname}.png`}
                    alt={coinData.Coin}
                    sx={{ width: 30, height: 30, mr: 1 }}
                    />
                    <Typography variant="h6">{coinData.coinname}</Typography>
                    <IconButton                    size="small"
                  variant="contained"
                  color="primary" onClick={handleTradeClick}>
                <ChangeCircleIcon />
              </IconButton>
                </Box>
                <Chip
                    size="small"
                    sx={{ ml: 0.1 }}
                    variant="outlined"
                      label={
                      <Typography
                        component="span" sx={{ display: 'inline', fontSize: '0.7rem' }}
                      >
                        {`${coinData['volume_category']}`}
                      </Typography>}
                      icon={
                        coinData['volume_category'] == '1-Low' ? (
                          <NightlightIcon style={{ fontSize: 16,color: '#9e9e9e' }}/>  // For significant negative values
                        ) : coinData['volume_category'] == '2-Medium' ? (
                          <PowerIcon style={{ fontSize: 15,color: '#ffffff' }}/>  // For negative values but not too severe
                        ) : coinData['volume_category'] == '3-High' ? (
                          <BoltIcon style={{ fontSize: 15,color: '#fffe00' }}/>  // For negative values but not too severe
                        ) : coinData['volume_category'] == '4-Significant' ? (
                          <WhatshotIcon style={{ fontSize: 15,color: '#fed962' }}/>  // For significant positive values
                        ) : (
                          <BoltIcon style={{color: '#b0bec5' }}/>  // Default icon for other cases
                        )
                      }
                      style={{
                        backgroundColor: 
                        coinData['volume_category'] === '1-Low' ? '#b0bec5' :  // Low volume category
                        coinData['volume_category'] === '2-Medium' ? '#42a5f5' :  // Medium volume category
                        coinData['volume_category'] === '3-High' ? '#ffb74d' :  // High volume category
                        coinData['volume_category'] === '4-Significant' ? '#ef5350' :  // Significant volume category
                          '#9e9e9e',  // Default color for other cases
                        color: 'white',  // White text to contrast the background
  
                      }}
                      
                    />

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1">
                Price: 
                </Typography>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    ${coinData["Close price"]}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <CandleChart coinname={coinData.coinname} />
            </Box>

            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
              {exchanges.map((exchange) => (
                <MenuItem
                  key={exchange.name}
                  onClick={() => {
                    window.open(exchange.link(coinData.coinname), '_blank');
                    handleClose();
                  }}
                >
                  <Avatar src={`${process.env.PUBLIC_URL}/PNG/${exchange.icon}`}  alt={exchange.name} x={{ width: 18, height: 18, mr: 1 }} />
                  {exchange.name}
                </MenuItem>
              ))}
            </Menu>
            <Box
                sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: '16px',
                }}
            >
                <Typography variant="body1">
                <strong>Short-term price:</strong>
                </Typography>
                <Typography variant="body1">
                $ {coinData?.["level20"]}
                </Typography>
            </Box>

            <Box
                sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: '16px',
                }}
            >
                <Typography variant="body1">
                <strong>Short-term (%):</strong>
                </Typography>
                <Typography variant="body1">
                {coinData?.["Run 20 days"]?.toFixed(2) && (
                    <Chip
                    icon={coinData["Run 20 days"] < 0 ? <CallReceivedIcon /> : <ArrowOutwardIcon />}
                    label={`${Number(coinData["Run 20 days"]).toLocaleString()} %`}
                    variant="outlined"
                    color={coinData["Run 20 days"] < 0 ? "error" : "success"}
                    />
                )}
                </Typography>
            </Box>

            <Box
                sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: '16px',
                }}
            >
                <Typography variant="body1">
                <strong>Mid-term price:</strong>
                </Typography>
                <Typography variant="body1">
                $ {coinData?.["level"]}
                </Typography>
            </Box>
            <Box
                sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: '16px',
                }}
            >
                <Typography variant="body1">
                <strong>Mid-term (%):</strong>
                </Typography>
                <Typography variant="body1">
                {coinData?.["Run 50 days"]?.toFixed(2) && (
                    <Chip
                    icon={coinData["Run 50 days"] < 0 ? <CallReceivedIcon /> : <ArrowOutwardIcon />}
                    label={`${Number(coinData["Run 50 days"]).toLocaleString()} %`}
                    variant="outlined"
                    color={coinData["Run 50 days"] < 0 ? "error" : "success"}
                    />
                )}
                </Typography>
            </Box>
            <Box
                sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: '16px',
                }}
            >
                <Typography variant="body1">
                <strong>Volume Ratio:</strong>
                </Typography>
                <Typography variant="body1">
                 {coinData?.["evr"]?.toFixed(2)}
                </Typography>
            </Box>
            <Box
                sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: '16px',
                }}
            >
                <Typography variant="body1">
                <strong>ATH:</strong>
                </Typography>
                <Typography variant="body1">
                $ {coinData?.["alltimehigh"]}
                </Typography>
            </Box>
            <Box
                sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                marginBottom: '16px',
                }}
            >
                <Typography variant="body1">
                <strong>% from ATH:</strong>
                </Typography>
                <IOSSlider 
                aria-label="ios slider"
                valueLabelDisplay="on"
                defaultValue={coinData?.["% ATH"]?.toFixed(2)}
                disabled
                min={-100}
                max={0}
                />
            </Box>

            </Grid>
 
          </Grid>
        </CardContent>
      </Card>


    </Box>
  );
};

export default CoinDetails;
