import React, { useRef, useEffect, useState } from 'react';
import { createChart } from 'lightweight-charts';
import { ToggleButton, Box, Tooltip } from '@mui/material';

const CandlestickChart = () => {
  const chartContainerRef = useRef();
  const [chartData, setChartData] = useState([]);
  const [timeFrame, setTimeFrame] = useState('1D');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('http://localhost:5000/api/getMarketData?market=ETHUSDT');
        const data = await response.json();
        
        const transformedData = data.map(item => ({
          time: Math.floor(new Date(item.date).getTime() / 1000),
          open: parseFloat(item['Open price']) || 0,
          high: parseFloat(item['High price']) || 0,
          low: parseFloat(item['Low price']) || 0,
          close: parseFloat(item['Close price']) || 0,
        }));

        setChartData(transformedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (chartData.length === 0) return;

    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: chartContainerRef.current.clientHeight,
      layout: {
        backgroundColor: '#ffffff',
        textColor: '#000000',
      },
      grid: {
        vertLines: {
          color: '#e0e0e0',
          style: 0,
          visible: true,
        },
        horzLines: {
          color: '#e0e0e0',
          style: 0,
          visible: true,
        },
      },
      crossHair: {
        mode: 0,
      },
      priceScale: {
        borderColor: '#d1d4dc',
        scaleMargins: {
          top: 0.1,
          bottom: 0.1,
        },
      },
      timeScale: {
        borderColor: '#d1d4dc',
      },
    });

    const candlestickSeries = chart.addCandlestickSeries();
    candlestickSeries.setData(chartData);

    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      chart.remove();
      window.removeEventListener('resize', handleResize);
    };
  }, [chartData]);

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '370px', // Adjusted height for the overall container
        padding: '10px',
      }}
    >
      <ToggleButton
        value="1D"
        selected={true}
        color="primary"
         size="small"
        sx={{
          position: 'absolute',
          top: '10px',
          left: '10px',
        }}
      >
        1 Day
      </ToggleButton>

      <Tooltip title="4h time frame will be available per roadmap">
        <ToggleButton
          value="4h"
          disabled
          color="primary"
           size="small"
          sx={{
            position: 'absolute',
            top: '10px',
            left: '65px', // Positioning it next to the "1 Day" button
          }}
        >
          4h (Coming)
        </ToggleButton>
      </Tooltip>

      <Box
        ref={chartContainerRef}
        sx={{
          position: 'absolute',
          top: '60px', // Increased top margin to add spacing between buttons and chart
          width: '100%',
          height: '300px',
        }}
      />
    </Box>
  );
};

export default CandlestickChart;
