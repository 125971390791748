import React, { useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import Datagrid from './Datagrid';
import Datagridmobile from './Datagridmobile';
import Barchart from './Barchart';
import ToggleButton from './ToggleButton';
import Filterpage from './Filterpage';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useMediaQuery, Drawer, Divider, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Home, Info, Build, Mail } from '@mui/icons-material';
import About from './About';
import CoinDetails from './CoinDetails';
import SignUp from './Signup';
import CandlestickChart from './CandlestickChart';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import GridOnIcon from '@mui/icons-material/GridOn';
import PeopleIcon from '@mui/icons-material/People';
import Logo from './Logosite';
import CloseIcon from '@mui/icons-material/Close';
import CoinDetailsAbout from './CoinDetailsAbout';
import CoinDetailsLink from './CoinDetailsLink';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import SignIn from './Signin';
import App from './App';
const apiUrl = process.env.REACT_APP_API_URL;

const defaultTheme = createTheme();

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://stratalla.com/">
        Stratalla
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

export default function Dashboard() {
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');
  const navigate = useNavigate();
  const location = useLocation();
  const isFilterPage = location.pathname === '/filterpage';

  const toggleDrawer = () => {
    setOpen(!open);
  };
 

  // Drawer content with navigation
  const drawerContent = (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', padding: '16px' }}>
        <IconButton onClick={toggleDrawer}>
          {open ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
        <Typography variant="h6">{open ? 'Close Menu' : 'Open Menu'}</Typography>
      </div>
      <Divider />
      <List>
        {[
          { text: 'Crypto Screener', icon: <GridOnIcon />, route: '/' },
          { text: 'Crypto Bubbles', icon: <BubbleChartIcon />, route: '/crypto-bubbles' },
          { text: 'About us', icon: <PeopleIcon />, route: '/about' },
        ].map(({ text, icon, route }) => (
          <ListItem button key={text} onClick={() => { navigate(route); toggleDrawer(); }}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <HelmetProvider>

    <Helmet>
      <title>Crypto Screener - Best free crypto screener — Stratalla</title>
      <meta name="description" content="Analyze thousands of cryptocurrencies effortlessly with Stratalla’s crypto screener. Screen by swing percentages, volume, categories, all-time highs, and more to discover trending coins and investment opportunities."></meta>
      <link rel="canonical" href={`${apiUrl}`} />
 
      {/* Open Graph / Facebook */}
      <meta property="og:title" content={`Crypto Screener - Best free crypto screener — Stratalla`} />
      <meta property="og:description" content= {`Stratalla's crypto screener offers a streamlined way to screen cryptos by swing percentages, volume, categories, and all-time high data. Get real-time insights and make confident decisions in the crypto market.`} />
      <meta property="og:url" content={`${apiUrl}`} />
      <meta property="og:type" content="website" />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={`Crypto Screener - Best free crypto screener — Stratalla`} />
      <meta name="twitter:description" content= {`Stratalla's crypto screener offers a streamlined way to screen cryptos by swing percentages, volume, categories, and all-time high data. Get real-time insights and make confident decisions in the crypto market.`} />
      <meta name="twitter:url" content={`${apiUrl}`} />
    </Helmet>

      <ThemeProvider theme={defaultTheme}>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          {/* Simplified AppBar */}
          <AppBar position="absolute" sx={{ bgcolor: "#000000" }}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={toggleDrawer}>
                <MenuIcon />
              </IconButton>

              <Box
                sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                onClick={() => navigate('/')}  // Navigate to home page
              >
                <div style={{ margin: "0px 20px 0px 0px" }}>
                  <Logo />  {/* Assuming Logo is a component that renders your logo */}
                </div>
              </Box>

              {/* This will take up the remaining space and push the button to the right */}
              <Box sx={{ flexGrow: 1 }} />

              <Button
                variant="contained"
                sx={{ textTransform: 'none',display: 'none' }}
                onClick={() => navigate('/signin')}  // Navigate to Sign In page
              >
                Sign in
              </Button>
              
            </Toolbar>
          </AppBar>

          {/* Drawer */}
          <Drawer
            anchor="left"
            open={open}
            onClose={toggleDrawer}
            variant={isMobile ? 'temporary' : 'persistent'}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              '& .MuiDrawer-paper': {
                width: isMobile ? '100%' : `${drawerWidth}px`,
                height: '100%',
                boxSizing: 'border-box',
                position: 'fixed',
                top: 0,
                zIndex: theme => theme.zIndex.drawer + 1,
              },
            }}
          >
            <div role="presentation" onClick={toggleDrawer} onKeyDown={toggleDrawer}>
              {drawerContent}
            </div>
          </Drawer>
          {/* Main content */}
          <Box
            component="main"
            sx={{
              width: '100%',
              backgroundColor: theme => theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
            }}
          >
            <Toolbar />

            <Box sx={{ mt: 2, mb: 4 }}>
              <Routes>
                <Route
                  path="/"
                  element={
                    <Grid container spacing={3} justifyContent="center">
                      <Grid item xs={12} md={10}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          {isMobile ? <Datagridmobile /> : <Datagrid />}
                        </Paper>
                      </Grid>
                    </Grid>
                  }
                />
                <Route path="/crypto-bubbles" element={<Barchart />} />
                <Route path="/filterpage" element={<Filterpage />} />
                <Route path="/about" element={<About />} />
                <Route path="/cryptocurrency/:coinId" element={<CoinDetails />} />
                <Route path="/candle" element={<CandlestickChart />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/signin" element={<SignIn />} />
                <Route path="/cryptocurrency/:coinId" element={<CoinDetails />} />
                <Route path="/cryptocurrency/about/:coinId" element={<CoinDetailsAbout />} />
                <Route path="/cryptocurrency/links/:coinId" element={<CoinDetailsLink />} />
                <Route path="/crypto-screener" element={isMobile ? <Datagridmobile /> : <Datagrid />} />
              </Routes>
            </Box>
            <Box
              component="footer"
              sx={{
                py: 3,
                px: 2,
                mt: 'auto',
                backgroundColor: theme => theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <Box sx={{ mb: 2 }}>
                <IconButton color="inherit" component="a" href="https://x.com/stratalla" target="_blank">
                  <TwitterIcon />
                </IconButton>
                <IconButton color="inherit" component="a" href="https://www.instagram.com/stratallacrypto/" target="_blank">
                  <InstagramIcon />
                </IconButton>
              </Box>
              <Copyright sx={{ pt: 4 }} />
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    </HelmetProvider>
  );
}
