import React from "react";

const DetailsCell = ({ selectedCoin }) => {
  const handleDetailsClick = () => {
    const tradingViewUrl = `https://www.tradingview.com/chart/?symbol=BINANCE:${selectedCoin}USDT&share_your_love=djoleivanovic`;
    
    window.open(tradingViewUrl, '_blank');
  };

  return (
    <img
      src={`${process.env.PUBLIC_URL}/PNG/tv.png`}
      alt="Trading View"
      width="24"
      height="24"
      style={{ cursor: 'pointer', margin: '0px 0px 0px 20px' }}
      onClick={handleDetailsClick}
    />
  );
};

export default DetailsCell;
