import React from 'react';
import { Box } from '@mui/material';

const Logo = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <img
        src={`${process.env.PUBLIC_URL}/logo.png`}
        alt="Logo"
        style={{ width: '150px', height: 'auto' }}
      />
    </Box>
  );
};

export default Logo;