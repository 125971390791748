import React, { useRef, useEffect, useState } from 'react';
import { createChart } from 'lightweight-charts';
import { Box, ToggleButton, Tooltip, MenuItem, FormControl, Select, InputLabel, Typography } from '@mui/material';
const apiUrl = process.env.REACT_APP_API_URL;

const Sveca = ({ coinname }) => {
    const chartContainerRef = useRef();
    const tooltipRef = useRef();  // Reference for the tooltip
    const [chartData, setChartData] = useState([]);
    const [chartVolumeData, setVolumeChartData] = useState([]);
    const [markers, setMarkers] = useState([]);
    const [term, setTerm] = useState('short-term');
    const [hoveredCandle, setHoveredCandle] = useState(null);



    function countDecimalPlaces(num) {
    // Convert number to string
    const numStr = num.toString();
    
    // Check if there's a decimal point
    if (numStr.includes('.')) {
        // Split the string at the decimal point and get the length of the fractional part
        return numStr.split('.')[1].length;
    }
    
    // If no decimal point, return 0
    return 0;
}
    useEffect(() => {
        const processData = (data, selectedTerm) => {
            const uniqueValues = new Set();
            const markerField = selectedTerm === 'short-term' ? 'level20' : 'level';
        
            data.forEach(item => {
                if (item[markerField] !== 0) {
                    uniqueValues.add(item[markerField]);
                }
            });
        
            // Convert Set to Array and sort to identify the latest value
            const uniqueValuesArray = Array.from(uniqueValues);
            const latestValue = uniqueValuesArray.reduce((latest, value) => {
                const item = data.find(item => item[markerField] === value);
                return item && new Date(item.date) > new Date(latest.date) ? item : latest;
            }, data[0])[markerField];
        
            const markers = uniqueValuesArray.map(value => {
                const isLatest = value === latestValue;
                return {
                    time: data.find(item => item[markerField] === value)?.date,
                    position: 'aboveBar',
                    color: isLatest ? '#082e8c' : 'gray',
                    shape: 'arrowDown',
                    text: value.toString()
                };
            });
        
            // Step 1: Find the latest day in the array of days
            const latestData = data.reduce((latest, item) => {
                const itemDate = new Date(item.date);
                return itemDate > new Date(latest.date) ? item : latest;
            }, data[0]);
        
            // Step 2: Determine the text to use based on the selected term
            const textValue = selectedTerm === 'short-term'
                ? latestData['Run 20 days']
                : latestData['Run 50 days'];
            
            const text = textValue.toFixed(2);
        
            // Step 3: Determine the color based on the value
            const color = textValue > 0 ? 'green' : (textValue === 0 ? 'gray' : 'red');
        
            // Step 4: Create the new marker object with the correct text and color
            const newMarker = {
                time: latestData.date,
                position: 'belowBar',  // Below the bar
                color: color,          // Set the color based on the value
                shape: 'arrowUp',      // Arrow pointing up
                text: `${selectedTerm === 'short-term' ? 'Short-term' : 'Mid-term'}: ${text}`+'%', // Run 20 or 50 days value, formatted to 2 decimal places
            };
        
            markers.push(newMarker);
        
            return markers;
        };

        const fetchData = async () => {
            try {
                const response = await fetch(`${apiUrl}/api/getMarketData?market=${coinname}USDT`);
                const data = await response.json();
                const processedMarkers = processData(data, term);
                setMarkers(processedMarkers);

                const transformedVolumeData = data.map(item => ({
                    time: Math.floor(new Date(item.date).getTime() / 1000),
                    value: parseFloat(item['Volume']) || 0,
                    color: item['volume_color']
                }));




                const transformedData = data.map(item => ({
                    time: Math.floor(new Date(item.date).getTime() / 1000),
                    open: parseFloat(item['Open price']) || 0,
                    high: parseFloat(item['High price']) || 0,
                    low: parseFloat(item['Low price']) || 0,
                    close: parseFloat(item['Close price']) || 0,
                }));

                setChartData(transformedData);
                setVolumeChartData(transformedVolumeData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [coinname, term]);

    useEffect(() => {
        if (chartData.length === 0) return;
        if (chartVolumeData.length === 0) return;
        const chart = createChart(chartContainerRef.current, {
            width: chartContainerRef.current.clientWidth,
            height: chartContainerRef.current.clientHeight,
            layout: {
                backgroundColor: '#ffffff',
                textColor: '#000000',
            },
            
            rightPriceScale: {
                borderVisible: false,
            },
            grid: {
                vertLines: {
                    color: '#e0e0e0',
                    style: 0,
                    visible: true,
                },
                horzLines: {
                    color: '#e0e0e0',
                    style: 0,
                    visible: true,
                },
            },
            crossHair: {
                mode: 0,
            },
            priceScale: {
                borderColor: '#d1d4dc',
                scaleMargins: {
                    top: 0.1,
                    bottom: 0.1,
                },
            },
            timeScale: {
                borderColor: '#d1d4dc',
                rightOffset: 8,   
            },
        });

        const candlestickSeries = chart.addCandlestickSeries();

        const volumeSeries = chart.addHistogramSeries({
            priceFormat: {
                type: 'volume',
            },
            priceScaleId: '', // set as an overlay by setting a blank priceScaleId
        });
        volumeSeries.priceScale().applyOptions({
            // set the positioning of the volume series
            scaleMargins: {
                top: 0.7, // highest point of the series will be 70% away from the top
                bottom: 0,
            },
        });

        candlestickSeries.setData(chartData);
        candlestickSeries.setMarkers(markers);
        volumeSeries.setData(chartVolumeData);

        // Create tooltip element
        const toolTip = document.createElement('div');
        toolTip.style = `
            width: 150px;
            height: auto;
            position: absolute;
            display: none;
            padding: 8px;
            box-sizing: border-box;
            font-size: 12px;
            text-align: left;
            z-index: 1000;
            pointer-events: none;
            border-radius: 4px;
            background-color: rgba(255, 255, 255, 0.75);
            color: black;
            border: 1px solid #5584f7;
            box-shadow: 0 2px 5px 0 rgba(117, 134, 150, 0.45);
        `;
        chartContainerRef.current.appendChild(toolTip);
        tooltipRef.current = toolTip;

        // Handle hover events to update the tooltip
        chart.subscribeCrosshairMove(param => {
            if (
                param.point === undefined ||
                !param.time ||
                param.point.x < 0 ||
                param.point.x > chartContainerRef.current.clientWidth ||
                param.point.y < 0 ||
                param.point.y > chartContainerRef.current.clientHeight
            ) {
                toolTip.style.display = 'none';
                setHoveredCandle(null);
            } else {
                const dateStr = new Date(param.time * 1000).toLocaleDateString();
                const priceData = param.seriesData.get(candlestickSeries);
                if (priceData) {
                    setHoveredCandle(priceData);
                    toolTip.innerHTML = `
                        <div>⬤ ${coinname}</div>
                        <div>Date: ${dateStr}</div>
                        <div>O: ${priceData.open}</div>
                        <div>H: ${priceData.high}</div>
                        <div>L: ${priceData.low}</div>
                        <div>C: ${priceData.close}</div>
                    `;
                    toolTip.style.display = 'block';

                    const tooltipWidth = toolTip.offsetWidth;
                    let left = param.point.x - tooltipWidth / 2;
                    left = Math.max(0, Math.min(left, chartContainerRef.current.clientWidth - tooltipWidth));
                    toolTip.style.left = left + 'px';
                    toolTip.style.top = (param.point.y - toolTip.offsetHeight - 10) + 'px';
                }
            }
        });
   

        const handleResize = () => {
            chart.resize(chartContainerRef.current.clientWidth, chartContainerRef.current.clientHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            chart.remove();
            window.removeEventListener('resize', handleResize);
            if (tooltipRef.current && chartContainerRef.current && chartContainerRef.current.contains(tooltipRef.current)) {
                chartContainerRef.current.removeChild(tooltipRef.current);
            }
        };
    }, [chartData, markers, coinname]);

    const handleTermChange = (event) => {
        setTerm(event.target.value);
    };

    return (
        <Box
            sx={{
                position: 'relative',
                width: '100%',
                height: '370px',
                padding: '10px',
            }}
        >
            {/* Price labels positioned above the toggle buttons */}
            {hoveredCandle && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '5px',  // Positioned above the buttons
                        left: '10px',  // Aligned to the left with the buttons
                        display: 'flex',
                        gap: '10px',
                    }}
                >
                    <Typography variant="caption">O: {hoveredCandle.open}</Typography>
                    <Typography variant="caption">H: {hoveredCandle.high}</Typography>
                    <Typography variant="caption">L: {hoveredCandle.low}</Typography>
                    <Typography variant="caption">C: {hoveredCandle.close}</Typography>
                </Box>
            )}

            <ToggleButton
                value="1D"
                selected={true}
                color="primary"
                size="small"
                sx={{
                    position: 'absolute',
                    top: '30px', // Adjusted to create space for the labels
                    left: '10px',
                }}
            >
                1 Day
            </ToggleButton>

            <Tooltip title="4h time frame will be available per roadmap">
                <ToggleButton
                    value="4h"
                    disabled
                    color="primary"
                    size="small"
                    sx={{
                        position: 'absolute',
                        top: '30px', // Adjusted to create space for the labels
                        left: '65px',
                    }}
                >
                    4h (Coming)
                </ToggleButton>
            </Tooltip>

            {/* Dropdown for Short Term / Mid Term */}
            <FormControl
                size="small"
                sx={{
                    position: 'absolute',
                    top: '30px',  // Adjusted to create space for the labels
                    right: '1px',
                    minWidth: 100                    
                }}
            >
                <InputLabel id="term-select-label">Time Frame</InputLabel>
                <Select
                    labelId="term-select-label"
                    id="term-select"
                    size="small"
                    value={term}
                    label="Term"
                    onChange={handleTermChange}
                >
                    <MenuItem value="short-term">Short Term</MenuItem>
                    <MenuItem value="mid-term">Mid Term</MenuItem>
                </Select>
            </FormControl>

            <Box
                ref={chartContainerRef}
                sx={{
                    position: 'absolute',
                    top: '60px',
                    width: '100%',
                    height: '300px',
                    marginTop:'20px',
                }}
            />
        </Box>
    );
};

export default Sveca;
