import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  IconButton,
  Chip,
  Skeleton,
  Avatar,
  Menu,
  MenuItem,
  Select,
  FormControl,
  InputLabel
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import FilterListIcon from '@mui/icons-material/FilterList';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import MediumPriorityIcon from '@mui/icons-material/ArrowUpward';  // ArrowUp for medium
import HighPriorityIcon from '@mui/icons-material/ArrowUpward';  // Upward arrow for high, can be more intense
import SignificantIcon from '@mui/icons-material/KeyboardArrowUp';  // Bigger upward arrow for significant
import FlatIcon from '@mui/icons-material/Remove'; 
import NightlightIcon from '@mui/icons-material/Nightlight';
import BoltIcon from '@mui/icons-material/Bolt';
import PowerIcon from '@mui/icons-material/Power';
import { Helmet, HelmetProvider } from 'react-helmet-async';
 

//const API_URL = 'http://localhost:5000/api/getAllData';
//const API_URL = '/api/api/getAllData';

const apiUrl = process.env.REACT_APP_API_URL;

const Datagridmobile = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState([]);
  const [sortField, setSortField] = useState('id');
  const [sortDirection, setSortDirection] = useState('asc');
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCoin, setSelectedCoin] = useState(null);
  const [isFilterActive, setIsFilterActive] = useState(false); // Added state variable
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state?.filter) {
      setFilter(location.state.filter);
      setIsFilterActive(true); // Set filter active if filter is applied
    }
    fetchData();
  }, [location.state]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/getAllData`);
      setData(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleTradeClick = (event, coinname) => {
    event.stopPropagation(); // Prevent the card's click event from firing
    setAnchorEl(event.currentTarget);
    setSelectedCoin(coinname);
  };

  const handleClose = (event) => {
    event.stopPropagation(); // Prevents unintended side effects
    setAnchorEl(null); // Closes the menu
  };
  


  const handleCardClick = (coinname) => {
    navigate(`/cryptocurrency/${coinname}`);
  };
  const exchanges = [
    { name: 'Binance', icon: 'binance.png', link: (coinname) => `https://www.binance.com/en/trade/${coinname}_USDT?type=spot` },
    { name: 'Gate.io', icon: 'gateio.png', link: (coinname) => `https://www.gate.io/trade/${coinname}_USDT` },
  ];

  const handleSortFieldChange = (event) => {
    setSortField(event.target.value);
  };

  const handleSortDirectionToggle = () => {
    setSortDirection(prevDirection => (prevDirection === 'asc' ? 'desc' : 'asc'));
  };

  const formatPercentage = (value) => value !== undefined ? value.toFixed(1) : 'N/A';

  const formatPrice = (value) => value !== undefined ? value.toFixed(2) : 'N/A';

  const getSortFieldValue = (coin) => {
    switch (sortField) {
      case 'shortTerm':
        return coin['Run 20 days'];
      case 'midTerm':
        return coin['Run 50 days'];
      case 'price':
        return coin['Close price'];
      case 'VolumeRatio':
        return coin['evr'];
      default:
        return coin.id;
    }
  };
  const filteredData = data
    .filter(coin => filter.length === 0 || filter.includes(coin.coinname))
    .sort((a, b) => {
      const aValue = getSortFieldValue(a);
      const bValue = getSortFieldValue(b);

      if (sortDirection === 'asc') {
        return aValue - bValue;
      } else {
        return bValue - aValue;
      }
    });

  const handleFilterClick = () => {
    setIsFilterActive(prev => !prev); // Toggle filter active state
    navigate('/filterpage');
  };
  const categoryData = {
    '1-Low': {
      label: '1-Low',
      color: '#b0bec5',  // Light gray
      icon: <NightlightIcon style={{ fontSize: 20 }} />,  // Neutral icon, flat line
    },
    '2-Medium': {
      label: '2-Medium',
      color: '#42a5f5',  // Vibrant blue
      icon: <PowerIcon style={{ fontSize: 20, color: '#ffffff' }} />,  // Up arrow for medium
    },
    '3-High': {
      label: '3-High',
      color: '#ffb74d',  // Bright orange
      icon: <BoltIcon style={{ fontSize: 20, color: '#fffe00' }} />,  // Up arrow for high
    },
    '4-Significant': {
      label: '4-Significant',
      color: '#ef5350',  // Strong red
      icon: <WhatshotIcon style={{ fontSize: 25, color: '#fed962' }} />,  // Bigger up arrow for significant
    },
  };
  
 

  return (
    
    
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center', // Center the content horizontally
          marginBottom: 1.5,
          fontWeight: 'bold',
          textAlign: 'center'
        }}
      >
        <Typography variant="h1" component="h1" sx={{ fontSize: '1.5rem', fontWeight: 'bold', textAlign: 'center' }}>
          Crypto Screener
        </Typography>
      </Box>
    <Helmet>
      <title>Crypto Screener - Best free crypto screener — Stratalla</title>
      <meta name="description" content="Analyze thousands of cryptocurrencies effortlessly with Stratalla’s crypto screener. Screen by swing percentages, volume, categories, all-time highs, and more to discover trending coins and investment opportunities."></meta>
      <link rel="canonical" href={`${apiUrl}/crypto-screener`} />
 
      {/* Open Graph / Facebook */}
      <meta property="og:title" content={`Crypto Screener - Best free crypto screener — Stratalla`} />
      <meta property="og:description" content= {`Stratalla's crypto screener offers a streamlined way to screen cryptos by swing percentages, volume, categories, and all-time high data. Get real-time insights and make confident decisions in the crypto market.`} />
      <meta property="og:url" content={`${apiUrl}/crypto-screener`} />
      <meta property="og:type" content="website" />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={`Crypto Screener - Best free crypto screener — Stratalla`} />
      <meta name="twitter:description" content= {`Stratalla's crypto screener offers a streamlined way to screen cryptos by swing percentages, volume, categories, and all-time high data. Get real-time insights and make confident decisions in the crypto market.`} />
      <meta name="twitter:url" content={`${apiUrl}/crypto-screener`} />
    </Helmet>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1, marginLeft: '10px' }}>
        <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
          <InputLabel sx={{ fontSize: '0.75rem' }}>Sort by</InputLabel>
          <Select
            value={sortField}
            onChange={handleSortFieldChange}
            label="Sort by"
            sx={{
              fontSize: '0.75rem', // Smaller font size for the Select component
              height: '32px', // Adjust the height of the Select component
              '.MuiSelect-select': {
                display: 'flex',
                alignItems: 'center',
                height: '100%', // Ensure the text is vertically centered
              },
              '.MuiOutlinedInput-notchedOutline': {
                borderRadius: '4px', // Adjust border radius if needed
              },
            }}
          >
            <MenuItem value="shortTerm" sx={{ fontSize: '0.75rem' }}>Short Term %</MenuItem>
            <MenuItem value="midTerm" sx={{ fontSize: '0.75rem' }}>Mid Term %</MenuItem>
            <MenuItem value="price" sx={{ fontSize: '0.75rem' }}>Price</MenuItem>
            <MenuItem value="VolumeRatio" sx={{ fontSize: '0.75rem' }}>Volume Ratio</MenuItem>
          </Select>
        </FormControl>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={handleSortDirectionToggle} size="small">
            {sortField === 'shortTerm' && (
              <>
                {sortDirection === 'asc' ? (
                  <ArrowUpwardIcon fontSize="small" color="primary" />
                ) : (
                  <ArrowDownwardIcon fontSize="small" color="primary" />
                )}
                <Typography variant="body2" sx={{ ml: 0.5 }}>
                  {sortDirection === 'asc' ? 'Ascending' : 'Descending'}
                </Typography>
              </>
            )}
            {sortField === 'VolumeRatio' && (
              <>
                {sortDirection === 'asc' ? (
                  <ArrowUpwardIcon fontSize="small" color="primary" />
                ) : (
                  <ArrowDownwardIcon fontSize="small" color="primary" />
                )}
                <Typography variant="body2" sx={{ ml: 0.5 }}>
                  {sortDirection === 'asc' ? 'Ascending' : 'Descending'}
                </Typography>
              </>
            )}
            {sortField === 'midTerm' && (
              <>
                {sortDirection === 'asc' ? (
                  <ArrowUpwardIcon fontSize="small"color="primary" />
                ) : (
                  <ArrowDownwardIcon fontSize="small" color="primary" />
                )}
                <Typography variant="body2" sx={{ ml: 0.5 }}>
                  {sortDirection === 'asc' ? 'Ascending' : 'Descending'}
                </Typography>
              </>
            )}
            {sortField === 'price' && (
              <>
                {sortDirection === 'asc' ? (
                  <ArrowUpwardIcon fontSize="small" color="primary" />
                ) : (
                  <ArrowDownwardIcon fontSize="small" color="primary" />
                )}
                <Typography variant="body2" sx={{ ml: 0.5 }}>
                  {sortDirection === 'asc' ? 'Ascending' : 'Descending'}
                </Typography>
              </>
            )}
          </IconButton>
        </Box>
        <IconButton size="small" variant="contained" onClick={handleFilterClick}>
          <FilterListIcon color={isFilterActive ? 'primary' : 'action'} />
        </IconButton>
      </Box>

      {loading ? (
        <Skeleton variant="rectangular" width="100%" height={400} />
      ) : (
        <Grid container spacing={1}>
          {filteredData.map((coin, index) => (
            <Grid item xs={12} sm={6} md={4} key={coin.id}>
              <Card
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: 0.5,
                  height: '60px',
                  border: 'none',
                  borderBottom: '1px solid #ddd',
                  borderRadius: 0,
                  boxShadow: 'none',
                  ...(index === 0 && { borderTop: '1px solid #ddd' })
                }}
                onClick={() => handleCardClick(coin.coinname)}
              >
                <Box
                  component="img"
                  src={`${process.env.PUBLIC_URL}/PNG/${coin.coinname}.png`}
                  alt={coin.Coin}
                  sx={{ width: 28, height: 28, mr: 1 }}
                />
                <Typography
                  component="a"
                  href={`/cryptocurrency/${coin.coinname}`}
                  variant="body2"
                  fontSize="0.7rem"
                  sx={{
                    width: '45px',
                    textDecoration: 'none',
                    color: 'inherit',
                    '&:hover': {
                      textDecoration: 'underline',
                      color: 'blue',
                    },
                  }}
                >
                  {coin.coinname}
                </Typography>


                <CardContent sx={{ flex: '1 0 auto', p: 0.5 }}>
                  <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', fontSize: '0.6rem' }}>
                    <Chip
                      label={
                        <Typography component="span" sx={{ display: 'inline', fontSize: '0.7rem' }}>
                          <Typography component="span" sx={{ color: 'gray', fontSize: '0.7rem' }}>
                            St
                          </Typography>
                          &nbsp;
                          <Typography
                            component="span"
                            sx={{
                              color: coin['Run 20 days'] < 0 ? 'error.main' : 'success.main',
                              fontSize: '0.7rem'
                            }}
                          >
                            {`${formatPercentage(coin['Run 20 days'])} %`}
                          </Typography>
                        </Typography>
                      }
                      size="small"
                      sx={{ ml: 0.1 }}
                      variant="outlined"
                    />
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', fontSize: '0.6rem',marginTop:'5px' }}>
                    <Chip
                      label={
                        <Typography component="span" sx={{ display: 'inline', fontSize: '0.7rem'}}>
                          <Typography component="span" sx={{ color: 'gray', fontSize: '0.7rem' }}>
                            VR
                          </Typography>
                          &nbsp;
                          <Typography
                            component="span"
                            sx={{
                              fontSize: '0.7rem'
                            }}
                          >
                            {`x ${formatPercentage(coin['evr'])}`}
                          </Typography>
                        </Typography>
                      }
                      size="small"
                      sx={{ ml: 0.1 }}
                      variant="outlined"
                    />
                  </Typography>
                </CardContent>
                <CardContent sx={{ flex: '1 0 auto', p: 0.5 }}>
                  <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', fontSize: '0.6rem' }}>
                    <Chip
                      label={
                        <Typography component="span" sx={{ display: 'inline', fontSize: '0.7rem' }}>
                          <Typography component="span" sx={{ color: 'gray', fontSize: '0.7rem' }}>
                            Mt
                          </Typography>
                          &nbsp;
                          <Typography
                            component="span"
                            sx={{
                              color: coin['Run 50 days'] < 0 ? 'error.main' : 'success.main',
                              fontSize: '0.7rem'
                            }}
                          >
                            {`${formatPercentage(coin['Run 50 days'])} %`}
                          </Typography>
                        </Typography>
                      }
                      size="small"
                      sx={{ ml: 0.1 }}
                      variant="outlined"
                    />
                  </Typography>
  

                  <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', fontSize: '0.6rem' ,marginTop:'5px'}}>
 
                    <Chip
                    size="small"
                    sx={{ ml: 0.1 }}
                    variant="outlined"
                      label={
                      <Typography
                        component="span" sx={{ display: 'inline', fontSize: '0.7rem' }}
                      >
                        {`${coin['volume_category']}`}
                      </Typography>}
                      icon={
                        coin['volume_category'] === '1-Low' ? (
                          <NightlightIcon style={{ fontSize: 16,color: '#9e9e9e' }}/>  // For significant negative values
                        ) : coin['volume_category'] === '2-Medium' ? (
                          <PowerIcon style={{ fontSize: 15,color: '#ffffff' }}/>  // For negative values but not too severe
                        ) : coin['volume_category'] === '3-High' ? (
                          <BoltIcon style={{ fontSize: 15,color: '#fffe00' }}/>  // For negative values but not too severe
                        ) : coin['volume_category'] === '4-Significant' ? (
                          <WhatshotIcon style={{ fontSize: 15,color: '#fed962' }}/>  // For significant positive values
                        ) : (
                          <BoltIcon style={{color: '#b0bec5' }}/>  // Default icon for other cases
                        )
                      }
                      style={{
                        backgroundColor: 
                          coin['volume_category'] === '1-Low' ? '#b0bec5' :  // Low volume category
                          coin['volume_category'] === '2-Medium' ? '#42a5f5' :  // Medium volume category
                          coin['volume_category'] === '3-High' ? '#ffb74d' :  // High volume category
                          coin['volume_category'] === '4-Significant' ? '#ef5350' :  // Significant volume category
                          '#9e9e9e',  // Default color for other cases
                        color: 'white',  // White text to contrast the background
  
                      }}
                      
                    />

                  </Typography>
                </CardContent>
                <CardContent sx={{ flex: '1 0 auto', p: 0.5 }}>
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                        $ {coin['Close price']}
                    </Typography>
                </CardContent>

                <IconButton
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={(event) => handleTradeClick(event, coin.coinname)}
                >
                  <ChangeCircleIcon fontSize="medium" />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose} // Attach handleClose here
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  PaperProps={{ sx: {boxShadow: 'none',border: '1px solid #ddd', width: '150px' } }}
                >
                  {exchanges.map(exchange => (
                    <MenuItem
                      key={exchange.name}
                      component="a"
                      href={exchange.link(selectedCoin)}
                      target="_blank"
                      onClick={(event) => {
                        handleClose(event); // Call handleClose and pass the event
                      }}
                    >
                      <Avatar
                        src={`${process.env.PUBLIC_URL}/PNG/${exchange.icon}`}
                        alt={exchange.name}
                        sx={{ width: 24, height: 24, mr: 1 }}
                      />
                      {exchange.name}
                    </MenuItem>
                  ))}
                </Menu>


              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default Datagridmobile;
