import React, { useState, useEffect } from 'react';
import { Box, Button, Autocomplete, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL;

const Filterpage = () => {
  const [coins, setCoins] = useState([]);
  const [selectedCoins, setSelectedCoins] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch coins list from the Datagridmobile API
    const fetchCoins = async () => {
      try {
        //const response = await axios.get('http://localhost:5000/api/getAllData'); // Replace with your API URL
        const response = await axios.get(`${apiUrl}/api/getAllData`);
        setCoins(response.data.map(coin => coin.coinname));
      } catch (error) {
        console.error('Error fetching coins:', error);
      }
    };

    fetchCoins();
  }, []);

  const handleApplyFilter = () => {
    // Logic to filter the list of coins
    navigate('/', { state: { filter: selectedCoins } }); // Passing the selected filters
  };

  const handleCancel = () => {
    navigate('/'); // Go back to the dashboard without applying filter
  };

  return (
    <Box sx={{ padding: 2, backgroundColor: '#ffffff' }}> {/* Added background color */}
      <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
        Filter Page
      </Typography>
      <Autocomplete
        multiple
        options={coins}
        value={selectedCoins}
        onChange={(event, newValue) => setSelectedCoins(newValue)}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label="Select Coins" placeholder="Filter by coins" />
        )}
        sx={{ mb: 2 }}
      />

      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button variant="outlined" color="primary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={handleApplyFilter}>
          Apply
        </Button>
      </Box>
    </Box>
  );
};

export default Filterpage;
