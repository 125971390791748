import React, { useEffect, useRef, useState } from "react";
import { Box, Typography, IconButton, Chip, Slider, Button, Menu, MenuItem } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { styled } from '@mui/material/styles';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import DetailsCell from './DetailsCell'; // Adjust path as per your file structure

const IOSSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? '#0a84ff' : '#e0e0e0',
  height: 5,
  padding: '15px 0',
  '& .MuiSlider-thumb': {
    height: 12,
    width: 12,
    backgroundColor: '#dedede',
    boxShadow: '0 0 2px 0px rgba(0, 0, 0, 0.1)',
    '&:focus, &:hover, &.Mui-active': {
      boxShadow: '0px 0px 3px 1px rgba(0, 0, 0, 0.1)',
      '@media (hover: none)': {
        boxShadow: '0 0 1px 1px rgba(0, 0, 0, 0.1)',
      },
    },
    '&:before': {
      boxShadow:
        '0px 0px 1px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 1px 0px rgba(0,0,0,0.12)',
    },
  },
  '& .MuiSlider-valueLabel': {
    fontSize: 14,
    fontWeight: 'regular',
    top: 20,
    left: 10,
    backgroundColor: 'unset',
    color: theme.palette.text.primary,
    '&::before': {
      display: 'none',
    },
    '& *': {
      background: 'transparent',
      color: theme.palette.mode === 'dark' ? '#fff' : '#000',
    },
  },
  '& .MuiSlider-track': {
    border: 'none',
    height: 5,
    color: '#bfbebd',
  },
  '& .MuiSlider-rail': {
    opacity: 0.5,
    backgroundColor: '#d0d0d0',
  },
}));

const DetailsBox = ({ data, selectedCoin, onClose }) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const coinData = data.find(item => item.coinname === selectedCoin);

  // Close box when clicking outside
  const detailsRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (detailsRef.current && !detailsRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const handleClick = (event) => {
    event.stopPropagation(); // Prevent closing the box
    setMenuAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setMenuAnchorEl(null);
  };

  const exchanges = [
    { name: 'Binance', icon: 'binance.png', link: `https://www.binance.com/en/trade/${selectedCoin}_USDT?type=spot` },
    { name: 'Gate.io', icon: 'gateio.png', link: `https://www.gate.io/trade/${selectedCoin}_USDT` },
  ];

  return (
    <Box
      ref={detailsRef}
      sx={{
        position: "absolute",
        top: 0,
        right: 0,
        width: { xs: '100%', sm: '400px' },
        height: { xs: '50%', sm: '100%' },
        backgroundColor: "#f0f0f0",
        padding: "20px",
        borderLeft: "1px solid #ccc",
        boxShadow: "-3px 0 5px rgba(0, 0, 0, 0.1)",
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <IconButton
        sx={{ alignSelf: 'flex-end' }}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      <Typography
        variant="h5"
        gutterBottom
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <a
          href={`https://www.binance.com/en/trade/${selectedCoin}_USDT?type=spot`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={`${process.env.PUBLIC_URL}/PNG/${selectedCoin}.png`}
            width="25"
            height="25"
            style={{ margin: '0 8px 0 0' }}
            alt={selectedCoin}
          />
        </a>
        {selectedCoin}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '16px',
        }}
      >
        <Typography variant="body1">
          <strong>Price:</strong>
        </Typography>
        <Typography variant="body1">
          $ {coinData?.["Close price"]}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '16px',
        }}
      >
        <Typography variant="body1">
          <strong>Short-term price:</strong>
        </Typography>
        <Typography variant="body1">
          $ {coinData?.["level20"]?.toFixed(2)}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '16px',
        }}
      >
        <Typography variant="body1">
          <strong>Short-term (%):</strong>
        </Typography>
        <Typography variant="body1">
          {coinData?.["Run 20 days"]?.toFixed(2) && (
            <Chip
              icon={coinData["Run 20 days"] < 0 ? <CallReceivedIcon /> : <ArrowOutwardIcon />}
              label={`${Number(coinData["Run 20 days"]).toLocaleString()} %`}
              variant="outlined"
              color={coinData["Run 20 days"] < 0 ? "error" : "success"}
            />
          )}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '16px',
        }}
      >
        <Typography variant="body1">
          <strong>Mid-term price:</strong>
        </Typography>
        <Typography variant="body1">
          $ {coinData?.["level"]?.toFixed(2)}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '16px',
        }}
      >
        <Typography variant="body1">
          <strong>Mid-term (%):</strong>
        </Typography>
        <Typography variant="body1">
          {coinData?.["Run 50 days"]?.toFixed(2) && (
            <Chip
              icon={coinData["Run 50 days"] < 0 ? <CallReceivedIcon /> : <ArrowOutwardIcon />}
              label={`${Number(coinData["Run 50 days"]).toLocaleString()} %`}
              variant="outlined"
              color={coinData["Run 50 days"] < 0 ? "error" : "success"}
            />
          )}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '16px',
        }}
      >
        <Typography variant="body1" sx={{ marginBottom: '8px' }}>
          <strong>ATH:</strong> {coinData?.["alltimehigh"]?.toFixed(2)}%
        </Typography>
        <Typography variant="body1">
          <strong>% from ATH:</strong>
        </Typography>
        <IOSSlider 
          aria-label="ios slider"
          valueLabelDisplay="on"
          defaultValue={coinData?.["% ATH"]?.toFixed(2)}
          disabled
          min={-100}
          max={0}
        />
      </Box>
 
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center', // Added to align items vertically
          marginBottom: '16px',
        }}
      > View On
        <DetailsCell selectedCoin={selectedCoin} />
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={handleClick}
          startIcon={<CurrencyExchangeIcon />}
          sx={{ marginLeft: 'auto' }} // Aligns button to the right
        >
          Trade {selectedCoin}
        </Button>
        <Menu
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {exchanges.map((exchange, index) => (
            <MenuItem key={index} onClick={() => window.open(exchange.link, '_blank')}>
              {exchange.name}
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </Box>
  );
};

export default DetailsBox;
