import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

const AboutPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>About Stratalla</title>
        <meta name="description" content="Learn about Stratalla, our mission, vision, and why we are the go-to platform for real-time crypto and stock analytics." />
        <meta name="keywords" content="Stratalla, About, Cryptocurrency, Stocks, Analytics, Market Trends" />
        <meta property="og:title" content="About Stratalla" />
        <meta property="og:description" content="Discover Stratalla, your trusted platform for real-time crypto and stock market insights and analytics." />
        <meta property="og:image" content="%PUBLIC_URL%/og-image.png" />
        <meta property="og:url" content="https://stratalla.com/about" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Stratalla" />
        <meta name="twitter:title" content="About Stratalla" />
        <meta name="twitter:description" content="Learn about Stratalla, the go-to platform for real-time crypto and stock market insights." />
        <meta name="twitter:image" content="https://stratalla.com/og-image.png" />
        <link rel="canonical" href="https://stratalla.com/about" />
      </Helmet>
      <Container maxWidth="lg">
        <Box sx={{ my: 4 }}>
          <Typography variant="h3" component="h1" gutterBottom>
            About Stratalla
          </Typography>
          <Typography variant="h4" component="h2" gutterBottom>
            Our Mission
          </Typography>
          <Typography variant="body1" paragraph>
            At Stratalla, we aim to empower both novice and seasoned traders with the tools, insights, and data necessary to navigate the complex world of cryptocurrency and stocks. We believe in transparency, accessibility, and innovation, ensuring that every user can make informed decisions and achieve their financial goals.
          </Typography>
          <Typography variant="h4" component="h2" gutterBottom>
            What We Do
          </Typography>
          <Typography variant="body1" paragraph>
            Stratalla is your go-to platform for real-time data, analytics, and trends in the crypto and stock markets. We offer a comprehensive dashboard that provides users with crucial information such as market swings, all-time highs, percentage changes, and price trends. Whether you’re tracking your favorite cryptocurrency or exploring new investment opportunities, Stratalla offers the insights you need to stay ahead of the curve.
          </Typography>
          <Typography variant="h4" component="h2" gutterBottom>
            Our Vision
          </Typography>
          <Typography variant="body1" paragraph>
            We envision a world where financial data is no longer a barrier but a bridge to financial independence. Stratalla is designed to break down complex data into actionable insights, making trading and investing more accessible to everyone, regardless of their experience level.
          </Typography>
          <Typography variant="h4" component="h2" gutterBottom>
            Why Choose Stratalla?
          </Typography>
          <Typography variant="body1" paragraph>
            - <strong>Real-Time Data:</strong> Stay updated with the latest market trends and data.
            <br />
            - <strong>User-Friendly Interface:</strong> Our platform is designed to be intuitive, making it easy for users to find the information they need.
            <br />
            - <strong>Comprehensive Analytics:</strong> Get detailed insights into market swings, price changes, and all-time highs.
            <br />
            - <strong>Responsive Design:</strong> Access Stratalla on any device, whether you’re at home or on the go.
          </Typography>
          <Typography variant="h4" component="h2" gutterBottom>
            Our Team
          </Typography>
          <Typography variant="body1" paragraph>
            Stratalla was founded by a group of passionate traders, data scientists, and tech enthusiasts who saw the need for a better way to analyze and track market trends. Our team is committed to continuous improvement, always working to enhance the platform and bring new features to our users.
          </Typography>
          <Typography variant="h4" component="h2" gutterBottom>
            Join Us
          </Typography>
          <Typography variant="body1" paragraph>
            Whether you're just getting started or are an experienced trader, Stratalla is here to support you. Join our growing community and take control of your financial future.
          </Typography>
        </Box>
      </Container>
    </HelmetProvider>
  );
};

export default AboutPage;
